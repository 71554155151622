import * as bootstrap from "bootstrap"

function initializeTooltips() {
  // Remove existing tooltips
  document.querySelectorAll('.tooltip').forEach(function(tooltipElement) {
    tooltipElement.remove();
  });

  // Initialize new tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.forEach(function (tooltipTriggerEl) {
    new bootstrap.Tooltip(tooltipTriggerEl);
  });
  //tooltipsInitialized = true;
}

export {
  initializeTooltips
};