import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="user-confirmation-modal"
export default class extends Controller {
  static targets = ["form", "saveBtn", "closeBtn"]

  connect() {
    this.saved = false
    let self = this
    const modal = Modal.getOrCreateInstance(this.element)

    // modal.show();
    this.element.addEventListener('hidden.bs.modal', event => {
      if (!self.saved) {
        self.submitForm()
      }
    })
  }

  submitForm() {
    const form = this.formTarget;
    const modal = Modal.getOrCreateInstance(this.element)
    const self = this

    const payload = new FormData(form);
    fetch(form.action, {
      method: 'POST',
      body: payload
    }).then(function(response) {
      self.saved = true
      modal.hide();
    })
  }
}
