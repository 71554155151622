import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="create-user-list-form"
export default class extends Controller {
  static targets = ["bookIdsInput", "bookIdInput", "submitFormBtn"]

  connect() {
  }

  submit(event) {
    // Check if currentPageBookIds is defined and is an array
    if (Array.isArray(window.currentPageBookIds)) {
      this.bookIdsInputTarget.value = window.currentPageBookIds.join();
    } else {
      // Handle the case where currentPageBookIds is not set
      this.bookIdsInputTarget.value = ""; // or any other default value as needed
    }
    
    this.bookIdInputTarget.value = window.selectedBookId;
  }
}
