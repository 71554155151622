import { Controller } from "@hotwired/stimulus"
import { sendEmailLoginLink } from "../authentication"

// Connects to data-controller="login-migration-failed"
export default class extends Controller {
  static targets = ["email", "msgResetPwd", "msgInvalidLogin", "formInputs", 'loginBtn', 'resetBtn']

  connect() {
  }

  submitForm(event) {
    console.log('this: ', this)
    console.log('submitForm(): ', event)


    console.log("wtf", this.element.checkValidity())
    if (this.element.checkValidity()) {

      const submitterValue = event.submitter.value
 
      if (submitterValue === "Reset Password") {
        event.preventDefault()
        event.stopImmediatePropagation()

        sendEmailLoginLink(this.emailTarget.value)
        this.msgInvalidLoginTarget.classList.add("d-none")
        this.msgResetPwdTarget.classList.remove("d-none")
        this.formInputsTarget.classList.add("d-none")
      }
    }
  }
}
