export default function initAutocompleteCategories(url, selector, selectionFn) {
  const config = {
    placeHolder: "Search for genres...",
    data: {
      src: async (query) => {
        try {
          // Fetch Data from external Source
          const source = await fetch(`${url}?filter=${query}`);
          // Data is array of `Objects` | `Strings`
          const data = await source.json();
          return data;
        } catch (error) {
          return error;
        }
      },
      // Data 'Object' key to be searched
      keys: ["name"]
    },
    resultsList: {
      tag: "ul",
      class: "list-group autocomplete-results",
    },
    resultItem: {
      tag: "li",
      class: "list-group-item list-group-item-secondary autocomplete-results-item"
    },
    selector: selector,
    events: {
      input: {
          selection: selectionFn
      }
    }
  };
  return config;
}

