export default function initBookDateFilters(dateStartId, dateEndId) {
  const startDateSelect = document.getElementById(dateStartId);
  const endDateSelect = document.getElementById(dateEndId);

  // Check if both select elements exist
  if (!startDateSelect || !endDateSelect) {
    console.warn("One or both date select elements not found.");
    return; // Exit the function if either element is not found
  }
  
  // Store the original options for both select inputs
  const originalStartOptions = Array.from(startDateSelect.options).map(option => option.cloneNode(true));
  const originalEndOptions = Array.from(endDateSelect.options).map(option => option.cloneNode(true));

  function filterOptions(fromSelect, toSelect, originalOptions, comparisonFunc) {
    const selectedYear = parseInt(fromSelect.value, 10) || 0;
    const prevSelected = toSelect.value;

    // Reset options if "Any" is selected for end_date
    if (fromSelect.id === "date-end" && fromSelect.value === "") {
      toSelect.innerHTML = '';
      originalOptions.forEach(option => {
        toSelect.appendChild(option.cloneNode(true));
      });
      return;
    }

    // If "Any" is selected in start date, reset end date to "Any" and return
    if (fromSelect.id === "date-start" && fromSelect.value === "") {
      toSelect.value = "";
      return;
    }

    // Populate the options from the original list
    toSelect.innerHTML = '';
    let prevStillValid = false;

    originalOptions.forEach(option => {
      const optionYear = parseInt(option.value, 10);

      if (!optionYear || comparisonFunc(optionYear, selectedYear)) {
        if (option.value === prevSelected) {
          prevStillValid = true;
        }
        toSelect.appendChild(option.cloneNode(true));
      }
    });

    // If the previous selection is still valid, reselect it
    if (prevStillValid) {
      toSelect.value = prevSelected;
    }
  }

  // For start_date <= end_date
  startDateSelect.addEventListener("change", function() {
    filterOptions(startDateSelect, endDateSelect, originalEndOptions, (optionYear, selectedYear) => optionYear >= selectedYear);
  });

  // For end_date >= start_date
  endDateSelect.addEventListener("change", function() {
    filterOptions(endDateSelect, startDateSelect, originalStartOptions, (optionYear, selectedYear) => optionYear <= selectedYear);
  });

  // Run the filter on page load if there are pre-selected values
  if (startDateSelect.value) {
    startDateSelect.dispatchEvent(new Event("change"));
  }

  if (endDateSelect.value) {
    endDateSelect.dispatchEvent(new Event("change"));
  }
}