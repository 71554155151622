import { Controller } from "@hotwired/stimulus"
import { useDispatch } from 'stimulus-use'
import * as bootstrap from "bootstrap"

// Connects to data-controller="review-button"
export default class extends Controller {
  static targets = ["modal"]
  connect() {
    useDispatch(this);
  }

  launchModal(event) {
    console.log("launchModal: e: ", event)
    // const bookId = event.target.dataset.bookId;
    // const modalElement = document.getElementById("review_modal_" + bookId)
    // const modal = new bootstrap.Modal(modalElement);
    // modal.show();
  }
}
