function initUserBookLists() {
  window.currentPageBookIds = []
  console.log('DOMContentLoaded, adding book list event handlers');
  const bookListItems = document.getElementsByClassName('book-list-item');
  console.log('found ' + bookListItems.length + ' books on this page');
  for (let i = 0; i < bookListItems.length; i++) {
    let bookListItem = bookListItems[i];
    window.currentPageBookIds.push(bookListItem.dataset.book);
  }

  if (window.currentPageBookIds.length === 0) {
    console.log("No bookIds available. Form will not be submitted.");
    return;  // Exits the function, skipping the form submission
  }

  document.getElementById('book_ids').value = window.currentPageBookIds.join(",");
  console.log('DOMContentLoaded, submitting the user-book-actions-form');
  document.getElementById('user-book-actions-form').requestSubmit();
}

// The loadAdSenseScript and getCookie functions remain unchanged.
export {
  initUserBookLists
};