import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin-ranked-lists"
export default class extends Controller {
  static targets = [ "checkbox", "destroy" ]
  connect(e) {
  }

  checkboxTargetConnected(e) {
  }

  checkboxClicked() {
    if (this.hasDestroyTarget) {
      if (this.checkboxTarget.checked) {
        this.destroyTarget.value = "0";
      } else {
        this.destroyTarget.value = "1";
      }
    }
  }
}
