import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap";
import { Notify } from 'notiflix/build/notiflix-notify-aio';

// Connects to data-controller="contact"
export default class extends Controller {
  connect() {
    console.log("Contact controller connected");
  }

  closeModal(event) {
    // Allow the form to continue submission
    //this.formTarget.requestSubmit();

    const modalElement = document.getElementById('contactModal');
    const modalInstance = Modal.getInstance(modalElement);
    modalInstance.hide();
    Notify.success("Your message was sent!")
  }
  
}
