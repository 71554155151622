import { Controller } from "@hotwired/stimulus"
import { initFirebaseApp, signOut } from "../authentication";

// Connects to data-controller="logout"
export default class extends Controller {
  static targets = [ "form" ]
  connect() {
    this.firebaseApp = initFirebaseApp();
  }

  logoutAndSubmitForm(event) {
    event.preventDefault();
    let self = this;
    // signout of firebase via javascript api
    signOut(this.firebaseApp).then(function() {
      // submit the form to signout on serverside
      console.log("self.element.parentElement: ", self.element);
      self.element.submit();
    })
  }
}
