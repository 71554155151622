import { Controller } from "@hotwired/stimulus"
import { loginUserWithEmailAndPwd, sendResetPasswordEmail } from "../authentication"
import { Modal } from "bootstrap"

// Connects to data-controller="auth-form"
export default class extends Controller {
  static targets = ["email", "password", "firstLoad", "error1", "error2", "resetBtn", "loginBtn", "msgResetPwd", "formInputs"]

  connect() {
    console.log('auth-form-controller connect: ', this)
    console.log(this.emailTarget.value);
    
    if (this.firstLoadTarget.value === "true") {
      console.log("first load, we should post to firebase");
      this.#doLogin()
    }    
  }

  #doLogin() {
    loginUserWithEmailAndPwd(this.emailTarget.value, this.passwordTarget.value)
      .then((loginSuccessStatus) => {
        // Handle the success case
        console.log('Login and subsequent actions were successful:', loginSuccessStatus);
        
        const modalElement = document.getElementById('authModal'); // Replace with your modal's ID
        const modalInstance = Modal.getInstance(modalElement)
        modalInstance.hide();
      })
      .catch((error) => {
        // Handle or log the rethrown error here
        console.error('An error occurred:', error);
        if ( error.code === 'auth/too-many-requests') {
          console.log('auth/too-many-requests');
          this.error1Target.classList.add("d-none")
          this.error2Target.classList.remove("d-none")
          this.passwordTarget.parentNode.classList.add("d-none")
          this.loginBtnTarget.classList.add("d-none")
          this.resetBtnTarget.classList.remove("d-none")
        } else if (error.code === 'auth/wrong-password') {
          console.log('auth/wrong-password')
          this.error2Target.classList.add("d-none")
          this.error1Target.classList.remove("d-none")
          this.passwordTarget.parentNode.classList.remove("d-none")
          this.loginBtnTarget.classList.remove("d-none")
          this.resetBtnTarget.classList.remove("d-none")
        // in this case, the user was found, but server side password
        // check failed
        } else if (error.code === 'auth/user-not-found') {
          console.log('auth/user-not-found')
        } else {
          console.log('unhandled error: ', error.code)
        }
        
      });

    this.firstLoadTarget.value = false
  }

  submitForm(event) {
    console.log('this: ', this)
    console.log('submitForm(): ', event)
    event.preventDefault()
    event.stopImmediatePropagation()

    console.log("wtf", this.element.checkValidity())
    if (this.element.checkValidity()) {

      const submitterValue = event.submitter.value

      if (submitterValue === "Reset Password") {
        sendResetPasswordEmail(this.emailTarget.value)
        this.error1Target.classList.add("d-none")
        this.error2Target.classList.add("d-none")
        this.msgResetPwdTarget.classList.remove("d-none")
        this.formInputsTarget.classList.add("d-none")
      } else if (submitterValue === "Login") {
        this.#doLogin()
      }
    } else {
      // Trigger form validation and show browser validation messages
      this.element.reportValidity();
    }
  }
}


