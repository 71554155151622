import { Controller } from "@hotwired/stimulus"
import { initUserBookLists } from "../user_lists"
import { insertAds } from "../ads"

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static values = {
    url: String
  }
  
  connect() {
    console.log('infinite-scroll connected')
    console.log("this.urlValue: ", this.urlValue)
    history.pushState({}, "", this.urlValue);
    initUserBookLists()
    insertAds()
  }
}
