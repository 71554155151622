import { Controller } from "@hotwired/stimulus"
import { Notify } from 'notiflix/build/notiflix-notify-aio';

// Connects to data-controller="review-form"
export default class extends Controller {
  static targets = [ "rating", "stars", "star1", "star2", "star3", "star4", "star5" ]
  connect() {
  }

  initialize() {
    this.selectedRatingIndex = null;
  }

  ratingTargetConnected(element) {
    if (element.value !== null) {
      this.selectedRatingIndex = parseInt(element.value) - 1;
      this.#stylizeRatingStars(this.selectedRatingIndex);
    }
  }

  toggleOffAll(event) {
    if (this.selectedRatingIndex !== null) {
      this.#stylizeRatingStars(this.selectedRatingIndex);
      return;
    }

    for(i = 0; i < 5; i++) {
      let currentStar = this["star" + (i+1) + "Target"];
      currentStar.classList.add('bi-star');
      currentStar.classList.remove('bi-star-fill');
    }
  }

  #stylizeRatingStars(starIndex) {
    for(let i = 0; i < 5; i++) {
      let currentStar = this["star" + (i+1) + "Target"];
      const currentStarIndex = parseInt(currentStar.dataset.ratingIndex);

      if (currentStarIndex <= starIndex) {
        currentStar.classList.add('bi-star-fill');
        currentStar.classList.remove('bi-star');
      } else {
        currentStar.classList.add('bi-star');
        currentStar.classList.remove('bi-star-fill');
      }
    }
  }

  notify(event) {
    if (event.detail.success) {
      Notify.success("Book successfully reviewed")
    }
  }

  hover(event) {
    let element = event.currentTarget;
    let starIndex = parseInt(element.dataset.ratingIndex);
    this.#stylizeRatingStars(starIndex);
  }

  selectRating(event) {
    let element = event.currentTarget;
    let starIndex = parseInt(element.dataset.ratingIndex);
    this.selectedRatingIndex = starIndex;

    let rating = this.selectedRatingIndex + 1;
    this.ratingTarget.value = rating;

    this.#stylizeRatingStars(this.selectedRatingIndex);
  }
}
