import AutoCompleteJS from "@tarekraafat/autocomplete.js"
import initAutocompleteCategories from "./autocomplete-categories"

window.addEventListener('DOMContentLoaded', (event) => {
  const inputClass = 'category-autocomplete';
  const autocompleteCategoryElements = document.getElementsByClassName(inputClass);

  for (let element of autocompleteCategoryElements) {
    const elementId = element.id;

    const config = initAutocompleteCategories(`/genres/search.json`, '#' + elementId, function(e) {
      const clickedSlug = e.detail.selection.value.slug;

      // 1. Find an example category link on the current page with the class `category-link`
      const categoryLink = document.querySelector('.category-link');
      
      if (categoryLink) {
        // 2. Get the `data-slug` attribute value from the dataset of the element
        const currentSlug = categoryLink.dataset.slug;

        // 3. Create a new `href` by replacing the current slug with the clicked slug
        const newHref = categoryLink.href.replace(currentSlug, clickedSlug);

        // 4. Follow the link
        window.location.href = newHref;
      }
    });

    // Initialize the autocomplete for the current element
    const autoCompleteJS = new AutoCompleteJS(config);
  }
});
