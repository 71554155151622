import { Controller } from "@hotwired/stimulus"
import { loginWithEmailLink } from "../authentication"


// Connects to data-controller="finish-email-signup"
export default class extends Controller {
  static targets = ['email', 'password', 'loginBtn', 'msgEmailNotFound']
  connect() {
    let email = window.localStorage.getItem('emailForSignIn');
    if (email) {
      this.emailTarget.value = email;
      this.emailTarget.parentNode.classList.add("d-none")
    } else {
      this.msgEmailNotFoundTarget.classList.remove("d-none")
    }
  }

  submitForm(event) {
    loginWithEmailLink(this.emailTarget.value, this.passwordTarget.value)
  }
}
