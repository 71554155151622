import { Controller } from "@hotwired/stimulus"
import { Notify } from 'notiflix/build/notiflix-notify-aio';

// Connects to data-controller="user-list-books"
export default class extends Controller {
  static targets = ["submit"];

  submitTargetConnected(element) {
    element.addEventListener('click', function(e) {
      e.preventDefault();
      e.target.parentNode.requestSubmit();
    });
  }

  submitEndCreate(e) {
    if (e.detail.success) {
      Notify.success("Book successfully added to the list")
    }
  }

  submitEndDestroy(e) {
    if (e.detail.success) {
      Notify.success("Book successfully removed from the list")
    }
  }
}
