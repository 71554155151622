import { Controller } from "@hotwired/stimulus"
import { initializeTooltips } from "../tooltips"

// Connects to data-controller="user-list-items-manager"
export default class extends Controller {
  connect() {
    console.log('user-list-items-manager connect')
    initializeTooltips()

    document.querySelectorAll('.user-list-book-action-form').forEach(function(button) {
      button.addEventListener('click', function() {
        document.querySelectorAll('.user-list-book-action-form').forEach(function(button) {
          console.log('disabling button: ', button)
          button.disabled = true;
        });
      });
    });
  }
}
