import { Tab } from "bootstrap";

function isElementVisible(elem) {
    return elem.offsetParent !== null;
}

function initializeTabCheck() {
    if (!isElementVisible(document.querySelector('#details'))) {
        const listsTab = new Tab(document.querySelector('#lists-tab'));
        listsTab.show();
    }
}

// Regular expression to match both /books/<id> and /rc/<id>/books/<id>
var pathRegex = /^\/(rc\/\d+\/)?(books)|(items)\/\d+$/;

if (pathRegex.test(window.location.pathname)) {
    document.addEventListener('DOMContentLoaded', initializeTabCheck);
    window.addEventListener('resize', initializeTabCheck);
}
