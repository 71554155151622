import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ol-work-search"
export default class extends Controller {
  static targets = [ "submit" ]
  connect() {
  }

  submitForm() {
    this.element.submit();
  }
}
