import { Controller } from "@hotwired/stimulus"
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';

// Connects to data-controller="review-delete"
export default class extends Controller {
  connect() {
  }

  initialize() {
    this.confirmed = false;
  }

  confirm(event) {
    if (this.confirmed) {
      return;
    }

    event.preventDefault();
    Confirm.show(
      'Confirm',
      'Are you sure you want to delete this review?',
      'Yes',
      'No',
      () => {
        this.confirmed = true;
        this.element.click();
      },
      () => {
        // do nothing
      },
      {
      },
    );
  }
}
