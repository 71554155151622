import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="changeset-form"
export default class extends Controller {
  static targets = ["container"]

  connect() {
    console.log("Changeset form controller connected")
  }

  addField(event) {
    const button = event.currentTarget
    const field = button.dataset.field
    const container = this.containerTarget
    const newField = document.createElement('div')
    newField.className = 'input-group mb-2'
    newField.innerHTML = `
      <input type="text" name="changeset[change_data][${field}][]" class="form-control">
      <button type="button" class="btn btn-danger remove-field" data-action="click->changeset-form#removeField">Remove</button>
    `
    container.appendChild(newField)
  }

  removeField(event) {
    const button = event.currentTarget
    button.closest('.input-group').remove()
  }
}
