import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="purchase-button"
export default class extends Controller {
  static values = { url: String }

  connect() {
  }

  loadData(event) {
    event.preventDefault();
    
    const purchaseModal = new Modal('#purchaseModal', {})
    purchaseModal.show();

    fetch(this.urlValue)
    .then(response => response.text())
    .then(html => {
      const modalBody = document.querySelector('#purchaseModal .modal-body');
      modalBody.innerHTML = html;
    });
  }
}
