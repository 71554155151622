// app/javascript/controllers/global_canon_form_controller.js
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="global-canon-form"
export default class extends Controller {
  static targets = ["totalBooks", "nonfictionPercentage", "maxBooksPerCountry"]

  updateUrl(event) {
    event.preventDefault(); // Prevent the form from submitting the default way

    const totalBooks = this.totalBooksTarget.value
    const nonfictionPercentage = this.nonfictionPercentageTarget.value
    const maxBooksPerCountry = this.maxBooksPerCountryTarget.value

    let url = `/global-canon/total_books/${totalBooks}`
    
    if (nonfictionPercentage) {
      url += `/nonfiction/${nonfictionPercentage}`
    }
    
    if (maxBooksPerCountry) {
      url += `/max_per_country/${maxBooksPerCountry}`
    }

    window.location.href = url; // Update the location to the new URL
  }
}
