function checkFormsValidity(form_id, button_id, e) {
  var form = document.forms[form_id];
  var button = document.getElementById(button_id);
  if (form.checkValidity()) {
    button.disabled = false;
  } else {
    button.disabled = true;
  }
}

window.addEventListener('DOMContentLoaded', (event) => {
  var buttons = document.querySelectorAll('.new-custom-user-list-button');
  buttons.forEach(element => {
    element.addEventListener('click', function(e) {
      var book_id = e.target.dataset.book;

      const form = document.getElementById('create-custom-user-list-form');
      let bookIdInput = document.getElementById('new-custom-user-list-book-id-input');
      if (bookIdInput === null) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "book_id";
        input.value = book_id;
        input.id = 'new-custom-user-list-book-id-input';
        form.appendChild(input);
      } else {
        bookIdInput.value = book_id
      }
    });
  });

  var dateFilterForm = document.getElementsByClassName("date-filter-form-submit");
  for (let i = 0; i < dateFilterForm.length; i++) {
    dateFilterForm[i].addEventListener('click', function (event) {
      event.preventDefault();

      let formElement = this.closest('form');
      if (formElement == null) {
          formElement = document.getElementById("date-filter-form-modal");
      }

      // Get the current page URL
      let currentUrl = window.location.href;

      // Regular expression to remove the "/page/<id>" part of the URL
      let pageRegex = /\/page\/\d+/;
      // Remove the "/page/<id>" part
      let urlWithoutPage = currentUrl.replace(pageRegex, '');

      // Regular expression to match the variations at the end of the URL
      let dateRegex = /(\/since\/\d+)|(\/to\/\d+)|(\/from\/\d+\/to\/\d+)$/;
      // Remove the matching patterns
      let baseUrl = urlWithoutPage.replace(dateRegex, '');

      // Constructing URL with date filters
      let dateStart = formElement.querySelector(".date-start-input").value;
      let dateEnd = formElement.querySelector(".date-end-input").value;

      // Check if the URL contains "the-greatest" or "the-greatest-books"
      let genreUrlPattern = /\/the-greatest\/[^\/]+\/books/;
      let mainUrlPattern = /\/the-greatest-books/;

      // Ensure baseUrl does not end with a slash before appending
      if (baseUrl.endsWith('/')) {
          baseUrl = baseUrl.slice(0, -1);
      }

      // Handle URLs with no initial filters
      if (!genreUrlPattern.test(baseUrl) && !mainUrlPattern.test(baseUrl)) {
          baseUrl += "/the-greatest-books";
      }

      // Append the date filters based on the user input
      if (dateStart !== '' && dateEnd === '') {
          baseUrl += ("/since/" + dateStart);
      } else if (dateStart !== '' && dateEnd !== '') {
          baseUrl += ("/from/" + dateStart + "/to/" + dateEnd);
      } else if (dateStart === '' && dateEnd !== '') {
          baseUrl += ("/to/" + dateEnd);
      }

      window.location.assign(baseUrl);
    });
  }
  
  
  
  // window.addEventListener('turbo:submit-end', (event) => {
  //   console.log('turboframe end e: ', event);
  // });
  
  var form = document.getElementById("create-custom-user-list-form");
  if (form !== null) {
    var inputs = document.getElementById("create-custom-user-list-form").elements;
    // Iterate over the form controls
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].nodeName === "INPUT" && inputs[i].type === "text" && inputs[i].getAttribute('required') !== undefined) {
        inputs[i].onkeyup = checkFormsValidity.bind(null, 'create-custom-user-list-form', 'create-custom-user-list-form-create-btn');
      }
    }
    checkFormsValidity('create-custom-user-list-form', 'create-custom-user-list-form-create-btn');
  }
});

export function assignEventsToSubmitUserListForms() {
  var linkSubmitBtns = document.getElementsByClassName('submit-link');
  for (i = 0; i < linkSubmitBtns.length; i++) {
    linkSubmitBtns[i].addEventListener('click', function(e) {
      e.preventDefault();
      e.target.parentNode.requestSubmit();
    });
  }
}
// assignEventsToSubmitUserListForms();

document.addEventListener('turbo:frame-load', function() {
  var formElement = document.querySelector('form.email-login-link-color');
  if (formElement) {
    formElement.addEventListener('click', function(event) {
      // Ensure the form itself or the hidden input was clicked, but not the button
      if (event.target === this || event.target.name === 'authenticity_token') {
          var button = this.querySelector('.text-button');
          if (button) {
              button.click();
          }
      }
    });
  }
});
