import AutoCompleteJS from "@tarekraafat/autocomplete.js"
import initAutocompleteBooks from "./autocomplete-books"

const selectionFn = async function(event) {
  const feedback = event.detail;
  let selection = feedback.selection.value.id; // Assuming the key you want is 'value'

  if (selection === undefined) {
    console.log('selection is probably an ol_Work');
    const ol_work_id = feedback.selection.value.key;

    // Extract CSRF token from the meta tag
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    try {
      // Post to /books/create_from_ol_work with ol_work_id and CSRF token
      const response = await fetch('/books/create_from_ol_work', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrfToken, // Adding CSRF token in the header
          'Accept': 'application/json' // Expecting JSON response
        },
        body: `ol_work_id=${encodeURIComponent(ol_work_id)}&authenticity_token=${encodeURIComponent(csrfToken)}`
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const bookData = await response.json();
      console.log('Received book data: ', bookData);

      // Here you can set selection to the .id of bookData if needed
      selection = bookData.id;
    } catch (error) {
      console.error('Error posting to /books/create_from_ol_work: ', error);
    }
  }

  console.log("selection: ", selection)

  // You can access the input element using `feedback.event.target`
  const inputElement = feedback.event.target;

  // Create a hidden input to store the book ID
  const bookIdInput = document.createElement('input');
  bookIdInput.type = 'hidden';
  bookIdInput.name = 'user_list_book[book_id]'; // Ensure this matches the name expected by your Rails params
  bookIdInput.value = selection; // Assuming the ID is in the 'id' property of the selection object

  // Find the form that this input belongs to
  const form = document.getElementById("new-user-list-book-form")

  // Append the hidden input to the form
  form.appendChild(bookIdInput);

  const submitBtn = document.getElementById("autocomplete-submit-btn")
  // Submit the form
  submitBtn.click();
  document.getElementById("book_title_autocomplete").value = "";
};

document.addEventListener('turbo:frame-render', (event) => {
  const inputClass = 'book-autocomplete';
  const autocompleteBookElements = document.getElementsByClassName(inputClass);

  for (let element of autocompleteBookElements) {
    const elementId = element.id;

    const config = initAutocompleteBooks(`/autocomplete`, '#' + elementId, selectionFn, true, true);

    // Initialize the autocomplete for the current element
    const autoCompleteJS = new AutoCompleteJS(config);
  }
});
