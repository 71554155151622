  // Function to adjust the z-index of side-rail AdSense ins tags
function adjustSideRailAdsZIndex() {
  console.log("executing adjustSideRailAdsZIndex");
  // Select all AdSense ads with the data-side-rail-status attribute set to "displayed"
  const sideRailAds = document.querySelectorAll('ins.adsbygoogle[data-side-rail-status="displayed"]');

  sideRailAds.forEach(ad => {
    console.log("executing adjustSideRailAdsZIndex for ad: ", ad);
    // Check the current z-index and update it to a lower value
    const currentZIndex = window.getComputedStyle(ad).zIndex;
    console.log("executing adjustSideRailAdsZIndex currentZIndex: ", currentZIndex);
    ad.style.zIndex = '1'; // Set a lower z-index
  });
}

function insertAdIfApplicable(config) {
  const {
    selector,
    adClient,
    adSlot,
    adFormat,
    fullWidthResponsive = false,
    adLayoutKey = null,
    paddingBottom = null
  } = config;

  if (!getCookie('TgbHideAds')) {
    loadAdSenseScript(adClient, function() {
      const targetElements = document.querySelectorAll(selector);
      targetElements.forEach((targetElement) => {
        // Check if the target element already has an ad inserted
        if (!targetElement.querySelector('.adsbygoogle')) {
          const adUnit = document.createElement('ins');
          adUnit.className = 'adsbygoogle';
          adUnit.style.display = 'block';
          adUnit.setAttribute('data-ad-client', adClient);
          adUnit.setAttribute('data-ad-slot', adSlot);
          adUnit.setAttribute('data-ad-format', adFormat);
  
          if (fullWidthResponsive) {
            adUnit.setAttribute('data-full-width-responsive', 'true');
          }
  
          if (adLayoutKey) {
            adUnit.setAttribute('data-ad-layout-key', adLayoutKey);
          }
  
          if (paddingBottom !== null) {
            targetElement.style.paddingBottom = paddingBottom;
          }
  
          targetElement.appendChild(adUnit);
          setTimeout(function() {
            (adsbygoogle = window.adsbygoogle || []).push({});
          }, 100);
        }
      });
    });
  }
}

// Global variable to track the loading status of the AdSense script
var adsenseLoadingStatus = 'not_started';

function loadAdSenseScript(adClient, callback) {
  switch (adsenseLoadingStatus) {
    case 'loaded':
      callback(); // Script is already loaded, immediately call the callback
      break;
    case 'in_progress':
      // If the script is currently being loaded, wait for it to load
      // This can be done by attaching the callback to an event listener or a setInterval check
      var checkLoad = setInterval(function() {
        if (window.adsbygoogle && window.adsbygoogle.loaded) {
          clearInterval(checkLoad);
          callback();
        }
      }, 100); // Check every 100ms
      break;
    case 'not_started':
    default:
      adsenseLoadingStatus = 'in_progress';
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adClient}`;
      script.crossOrigin = "anonymous";
      document.head.appendChild(script);

      script.onload = function() {
        adsenseLoadingStatus = 'loaded';
        callback(); // Call the callback once the script is loaded
      };
      break;
  }
}

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function insertAds() {
  // Check if the current domain is the development domain
  if (window.location.hostname === 'dev.thegreatestbooks.org') {
    console.log('Skipping ad insertion on development site');
    return; // Exit the function early
  }

  // Log that ads are being added (production)
  console.log('Adding ads');

  // Insert ad in the in-feed ad slot
  insertAdIfApplicable({
    selector: '.in-feed-ad-slot',
    adClient: 'ca-pub-2193700692946691',
    adSlot: '2895606476',
    adFormat: 'fluid',
    adLayoutKey: '-f1+12-48-f0+16z',
    paddingBottom: '1.5rem'
  });

  // Insert ad in the sidebar for screens wider than 768px
  if (window.innerWidth >= 768) {
    insertAdIfApplicable({
      selector: '.multiplex-sidebar-ads',
      adClient: 'ca-pub-2193700692946691',
      adSlot: '6565573962',
      adFormat: 'autorelaxed'
    });
  }
}

function handleAdsAfterResize() {
  const adContainer = document.querySelector('.multiplex-sidebar-ads');
  if (window.innerWidth >= 768 && !adContainer.classList.contains('ad-loaded')) {
    insertAdIfApplicable({
      selector: '.multiplex-sidebar-ads',
      adClient: 'ca-pub-2193700692946691',
      adSlot: '6565573962',
      adFormat: 'autorelaxed'
    });
    adContainer.classList.add('ad-loaded');
  } else if (window.innerWidth < 768) {
    adContainer.innerHTML = '';  // Clear ad content
    adContainer.classList.remove('ad-loaded');
  }
}

// The loadAdSenseScript and getCookie functions remain unchanged.
export {
  insertAds,
  handleAdsAfterResize,
  adjustSideRailAdsZIndex
}